.ant-btn-primary.active,
.ant-btn-primary:active {
  border-radius: 0.7em !important;
  display: none;
  box-shadow: none !important;
  background-color: rgb(80, 80, 80);
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: rgba(255, 255, 255, 0);

  background-color: #ffffff00;
  border-color: #f5706e00;
  box-shadow: none;
}

.ellipse-small {
  -webkit-animation: pulse-animation 4s infinite;
  -webkit-animation-delay: 1.8s;
  animation: pulse-animation 4s infinite;
  animation-delay: 2.8s;
  transform: scale(0);
}

@keyframes pulse-animation {
  0% {
    transform: scale(0);
  }
  60% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    transform: scale(1.4);
  }
}
.ellipse-large {
  -webkit-animation: pulse-animation1 4s infinite;
  -webkit-animation-delay: 1s;
  animation: pulse-animation1 4s infinite;
  animation-delay: 1s;
  transform: scale(0);
}

@keyframes pulse-animation1 {
  0% {
    transform: scale(0);
  }
  60% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@font-face {
  font-family: "unineue";
  src: url("../static/fonts/UniNeueHeavy.otf");

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nunito";
  src: url("../static/fonts/Nunito-SemiBold.woff");
}
@font-face {
  font-family: "nunito-regular";
  src: url("../static/fonts/Nunito-Regular.woff");
}
.discover-button:hover {
  background-color: #e94347 !important;
  color: white !important;
}
.ant-input::placeholder {
  color: rgb(158, 158, 158) !important;
}
.card1,
.card2,
.card3,
.card4 {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.card1 {
  min-width: 60ch;
  min-height: 60ch;
  width: 45vw;
  height: 45vw;
  max-width: 100ch;
  max-height: 100ch;
  background-image: url(https://image.flaticon.com/icons/svg/119/119596.svg);
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-button {
}
.home-button:hover {
  transition: 0.3s;

  border: 0px solid #ffffff !important;
}
a.button-l-2 {
  right: 5%;
  top: -4em;
  position: absolute;
  z-index: 1;
  width: 8em;
  height: 8em;
  overflow: hidden;
  vertical-align: middle;
  text-align: left;
  text-decoration: none;
  line-height: 3em;
  font-size: 1rem;
  color: #e94347;
  cursor: pointer;
  -webkit-transform: translateZ(0);
  transform: translateZ(0) rotate(180deg) scale(0.8);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
a.button-l-2 svg {
  height: 20vh;
  width: 100vw;
  z-index: 1;
  position: absolute;
  left: -5em;
}
a.button-l-2 svg g line,
a.button-l-2 svg g polyline,
a.button-l-2 svg g circle {
  fill: none;
  stroke: #e94347;
  stroke-width: 0.1em;
}
a.button-l-2 svg g line,
a.button-l-2 svg g polyline {
  height: 10vh;
  width: 10vw;
  stroke-miterlimit: 10;
}
a.button-l-2 svg g circle {
  display: block;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.1em;
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset ease-out 0.5s;
}

font {
  right: 5.6%;
  top: 0em;
  font-stretch: condensed;
  font-family: "nunito-regular";
  color: #110f0f;
  text-align: left;
  z-index: 2;
  position: absolute;
  display: inline-block;
  font-size: 18px;
}

a.button-l-2:hover {
  -webkit-transform: translateZ(0) translateX(-1em) scale(0.8);
  transform: translateZ(0) translateX(-1em) rotate(180deg) scale(0.8);
}
a.button-l-2:hover svg g circle {
  stroke-dashoffset: -200;
}
@font-face {
  font-family: "Chenla";
  src: url("../static/fonts/Chenla.ttf");

  font-weight: normal;
  font-style: normal;
}
.ant-slider-mark-text {
  color: rgb(255, 255, 255);
}
.home-image {
  height: calc(100vh - 70px);
  min-height: 600px;
  max-height: 750px;
  margin-top: -5em;
  max-width: 55em;
  display: inline;
}
.home-image-mobile {
  display: none;
}
.burger-menu {
  display: none;
}
.ant-drawer-title {
  font-size: 28px !important;
  position: relative;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
  padding-bottom: 1em;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100vh;
}
.ant-drawer-header {
  font-size: 25px;
  margin: auto;
  margin-top: 1em;
  position: relative;
  padding: 16px 30px;

  background: #00d32700;

  border-bottom: 0px solid #3a8ea8;
  border-radius: 3em;
}
.ant-drawer-content {
  background-color: #132440;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #fff;
  border: 2px solid #f5222d;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #f5222d;
  font-weight: 700;
  padding-left: 1em;
}
.ant-drawer-close {
  position: absolute;
  font-size: 28px;
  top: -0.8em;
  transform: scale(0.6);
  border-radius: 5em;
  background-color: rgba(255, 255, 255, 0.13);
  color: #ffffff;
  right: 0em;
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: #ffffff18;
}
.home-back-mobile-1 {
  display: none;
}
.home-back-mobile {
  display: none;
}
.cardHeader {
  transition: all 500ms ease-in-out;
}
.cardImg {
  transition: all 500ms ease-in-out;
}
.cardItem {
  transition: all 500ms ease-in-out;
}
.what-we-boxes:hover .cardItem {
  transform: scale(1.05) !important;

  cursor: pointer;

  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.what-we-boxes:hover .cardHeader {
}
.what-we-boxes:hover .cardImg {
  filter: drop-shadow(8px 4px 3px #3d3d3d54);
  transform: skewY(3deg) scale(1.1);
  cursor: default;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.footer-image {
  display: inline;
  height: 80vh;
  min-width: 110em !important;
  min-height: 55em !important;
}
.footer-image-mobile {
  display: none;
}
.header {
  background: rgba(255, 255, 255, 0) !important;

  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
}
.headroom--unfixed {
  position: relative;

  transform: translateY(0);
}
.headroom--scrolled {
  opacity: 1 !important;
  transition: 400ms !important;
}
.headroom--unpinned {
  opacity: 0 !important;
  position: fixed;
  transform: translateY(-100%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}
.active.header {
  padding-top: 1.5em !important;
  padding-bottom: 1em !important;
}
.active .header-links-style {
  color: #ffffff !important;
}
.header-links-style:hover {
  color: #e94347 !important;
}
.active .header-links-button-style {
}

.active {
  background-color: #132440 !important;
}
.active .logo-image-green-active {
  display: inline;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 10px;
  right: 14px;
  border: none !important;
  border-radius: 3em;
  padding: 0;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.849);
  display: flex;
}
.react-responsive-modal-modal {
  text-align: center;
  border-radius: 1em;
  max-width: 500px !important;
  min-width: 300px !important;
  width: 30% !important;
  position: relative;
  padding: 2rem !important;
  background: #132440;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: auto;
}
.dots-left {
  display: none;
}
.dots-right {
  display: inline;
}
.service-image-small {
  display: none;
}
.dots-left2 {
  display: none;
}
.dots-left-idea {
  margin: auto !important;
  text-align: center !important;
  margin-left: 13.5em !important;
}
.dots-left-idea-mobile {
  display: none;
}
.service-row {
  min-width: 70em !important;
}
.dots-left-idea1 {
  display: none;
}
@media only screen and (max-width: 770px) {
  .send-message-button {
    text-align: center !important;
    min-width: 85vw !important;
    margin-right: 3.8em !important;
  }
  .contact-hidden {
    display: none;
  }
  .dots-left-idea-mobile {
    display: inline;
    margin: auto !important;
    text-align: center !important;
    margin-left: 4em !important;
  }
  .dots-left-idea {
    display: none;
  }
  .capsule-image {
    display: none;
  }
  .moon-image {
    width: 7em;
  }
  .dots-left-idea {
    margin: auto !important;
    text-align: center !important;
    margin-left: 12em !important;

    margin-top: 0.7em !important;
  }
  .dots-left-idea1 {
    display: inline;
    margin: auto !important;
    text-align: center !important;
    margin-left: 12em !important;
    margin-top: 0.7em !important;
  }
  .dots-left2 {
    margin: auto !important;
    text-align: center !important;
    margin-left: 12em !important;
  }
  .dots-left1 {
    display: none;
  }
  .dots-left {
    position: absolute;
    transform: rotate(180deg);
    margin: auto !important;

    text-align: center !important;
    margin-left: -50em !important;

    display: inline;
  }
  .dots-right {
    display: none;
  }

  .services-button {
    margin-top: -1em !important;
  }
  .ant-slider-step {
    height: 4px;

    background: #ffffff;
  }

  .input-budget-text {
    font-size: 15px;

    margin-left: 0em;
  }
  .ant-slider {
    width: 75vw;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.65);
    font-size: 19px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: "tnum", "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    height: 12px;
    margin: 0px 0px 20px;
    margin-left: 0em;

    padding: 4px 0;
    cursor: pointer;
    -ms-touch-action: none;
    touch-action: none;
  }
  .header {
    width: 100vw !important;
    z-index: 0 !important;
    display: inline;
    position: absolute !important;
    margin-bottom: -4em !important;
    padding-bottom: 0em !important;
  }
  .service-image-small {
    display: inline;
  }
  .burger-menu {
    background-color: transparent;

    display: inline;
  }
  .header-links {
    display: none;
  }
  .logo-image {
    z-index: 1000;
    visibility: visible;
    position: absolute;
    width: 4em !important;
    top: -1.1em;
    margin-left: 1em;
  }
  .logo-image-col {
    position: absolute;
  }
  .home-header {
    white-space: nowrap;
    font-size: 45px !important;
    text-align: center;
  }
  .what-we-do {
    margin-top: 5em !important;
    font-size: 40px !important;
    text-align: center;
  }
  .home-button {
    top: 18em;

    margin: auto !important;
    text-align: center !important;
  }
  .home-row {
    top: -0em !important;
    margin: auto !important;
    text-align: center !important;
  }
  .home-image {
    display: none;
  }
  .home-image-mobile {
    max-width: 22em;
    min-width: 8em;
    margin-top: -4.5em;
    display: inline;
  }
  .active.header {
    display: none;
  }
  .home-div {
    max-height: 20em !important;
    margin-bottom: -22em;
  }
  .home-back {
    display: none;
  }
  .home-back-mobile {
    display: inline;
    overflow: hidden;

    min-height: 50em !important;
    max-height: 56em !important;
  }
  .home-back-mobile-1 {
    display: inline;
    overflow: hidden;

    min-height: 85em !important;
  }
  .display-inline {
    text-align: center;
    display: inline !important;
  }
  .header-number {
    font-size: 30px !important;
    margin-left: 2em !important;
  }
  .header-number2 {
    font-size: 35px !important;
    margin-left: -1em !important;
    margin-top: -0.25em !important;
  }
  .header-number3 {
    font-size: 30px !important;
    margin-left: 1.6em !important;
    margin-top: -0.25em !important;
  }
  .center {
    margin: auto !important;
    text-align: center !important;
  }
  .center-header-why {
    max-width: 7.5em;
    font-size: 40px !important;
    margin: auto !important;
    margin-top: 2em !important;
    text-align: center !important;
  }
  .center-subheader-deep {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: -14em !important;
    text-align: center !important;
  }
  .center-subheader-rapid {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: 11.7em !important;
    text-align: center !important;
  }
  .center-subheader-competititve {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: 2em !important;
    text-align: center !important;
  }
  .center-subheader {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: 2em !important;
    text-align: center !important;
  }
  .center-sub-paragraph {
    line-height: 1.2em !important;
    font-size: 16px !important;
    margin: auto !important;
    text-align: center !important;
    margin-top: 1em !important;
  }
  .rates-image {
    text-align: center;
    margin: auto;
    max-width: 16em !important;
    margin-top: 15em !important;
  }
  .satelite-image {
    text-align: center;
    margin: auto;
    max-width: 16em !important;
    margin-top: 3.5em !important;
  }
  .space-image {
    text-align: center;
    margin: auto;
    max-width: 14em !important;
    margin-top: 16em !important;
  }
  .rapid-create {
    text-align: center;
    margin: auto;
    min-width: 8em !important;
    margin-top: 3.5em;
  }
  .expert-image {
    text-align: center;
    margin: auto;
    min-width: 15em !important;
    margin-top: 12em;
    margin-left: -2em;
  }
  .address-bar {
    display: none;
  }
  .footer-image {
    display: none;
  }
  .service-row {
    min-width: auto !important;
    margin-top: 3em !important;
  }
  .left-header1 {
    width: 100%;
    margin-bottom: 0em !important;
    margin-left: 0em !important;
    text-align: center !important;
  }
  .left-header-launch {
    width: 100%;
    margin-bottom: 0.5em !important;
    margin-left: -0.5em !important;
    text-align: center !important;
  }
  .left-paragraph1 {
    padding-right: 1em;
    margin-left: 1.3em !important;
    text-align: center !important;
  }
  .left-paragraph2 {
    margin-top: 0.6em !important;
    padding-right: 1em;
    margin-left: 0em !important;
    text-align: center !important;
  }
  .left-header {
    text-align: center !important;
    margin-bottom: -0.5em !important;
    margin-left: -1.5em;
  }
  .left-header-quality {
    text-align: center !important;
    margin-bottom: -0.5em !important;
    margin-left: -3.5em;
  }
  .left-paragraph-ui {
    padding-left: 0em !important;
    text-align: center !important;
    padding-right: 1em;
    width: 80%;
    margin-top: 1.7em !important;
    margin-left: 4.9em;
  }
  .left-paragraph-quality {
    padding-left: 0em !important;
    text-align: center !important;
    padding-right: 1em;
    width: 80%;
    margin-top: 1.7em !important;
    margin-left: 3em;
  }
  .left-paragraph-maintenance {
    padding-left: 0em !important;
    text-align: center !important;
    padding-right: 1em;
    width: 80%;
    margin-top: 1.7em !important;
    margin-left: 5.3em;
  }

  .service-image {
    display: none;
  }

  .footer-image-mobile {
    display: inline;

    display: inline;
    height: 70vh;
    margin-top: 4em !important;
    min-height: 53em !important;
  }
  .center-subheader-expert {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: -12.5em !important;
    text-align: center !important;
  }
  .center-subheader-agile {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: -14em !important;
    text-align: center !important;
  }

  .center-header {
    font-size: 40px !important;
    margin: auto !important;
    text-align: center !important;
  }
  .center-paragraph {
    font-size: 20px !important;
    margin: auto !important;
    text-align: center !important;
  }
  .center-header-contact {
    color: black !important;
    white-space: nowrap;
    font-size: 40px !important;
    margin: auto !important;
    margin-left: -0em !important;
    margin-bottom: 0.5em !important;
    margin-top: 1em !important;
    text-align: center !important;
  }

  .contact-div {
    width: 100vw;
    margin: auto !important;
    margin-left: -0.6em !important;
    text-align: center !important;
  }
  .center-paragraph-why1 {
    color: #110f0fd3 !important;
    white-space: nowrap;
    font-size: 20px !important;
    margin: auto !important;
    margin-left: -0em !important;

    margin-bottom: 7.5em !important;
    text-align: center !important;
  }
  .center-paragraph-why {
    font-size: 20px !important;
    margin: auto !important;
    margin-top: 1em !important;

    text-align: center !important;
  }
  .what-we-boxes {
    margin-top: 2em;
  }
  .process-img-row {
    margin-bottom: 0em !important;
  }
  .form-item-style {
    margin-left: auto !important;
    width: 77vw;
  }
  .form-item-style-number {
    margin-left: auto !important;

    width: 77vw;
  }
  .ellipse-small {
    left: -13em !important;
    -webkit-animation: pulse-animation 4s infinite;
    -webkit-animation-delay: 1.8s;
    animation: pulse-animation 4s infinite;
    animation-delay: 2.8s;
    transform: scale(0);
  }

  @keyframes pulse-animation {
    0% {
      transform: scale(0);
    }
    60% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }
  .ellipse-large {
    left: -13em !important;
    -webkit-animation: pulse-animation1 4s infinite;
    -webkit-animation-delay: 1s;
    animation: pulse-animation1 4s infinite;
    animation-delay: 1s;
    transform: scale(0);
  }

  @keyframes pulse-animation1 {
    0% {
      transform: scale(0);
    }
    60% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }
  .process-button {
    left: -2.5em !important;
    top: 60em !important;
    width: 14em !important;
  }
  .process-back {
    bottom: 0;
    min-height: 0em !important;
  }
  .our-process-header {
    font-weight: 500 !important;
    color: black !important;
    margin-top: 0.9em !important;
    line-height: 34px !important;
    font-size: 35px !important;
    width: auto !important;
    max-width: 16em;
  }
  .our-process-paragraph {
    padding-left: 2em !important;
    padding-right: 2em !important;
    margin-bottom: -0.5em;
    font-size: 18px !important;
  }
  .process-pic-mobile {
    transform: scale(1.1);
    top: -1em !important;
    right: 0em;
    left: -2.6em;

    display: inline !important;
  }
  .process-pic {
    display: none;
  }
  .process-header {
    visibility: hidden;
  }
  .life-cycle-line {
    margin-left: 2% !important;

    margin-top: -5em !important;
    transform: scale(0.85);
  }
  .process-paragraph {
    margin-left: 1.5em;
    text-align: center !important;
  }
  .services-div {
    margin-left: 0em !important;
  }
  .concept-paragraph {
    top: -1em !important;
    left: -2em !important;
  }
  .design-paragraph {
    top: 20em !important;
    left: -27em !important;
  }
  .testing-paragraph {
    top: 43em !important;
    left: -27em !important;
  }
  .interaction-paragraph {
    top: 4.5em !important;
    left: -3em !important;
  }
  .development-paragraph {
    top: 27em !important;
    left: -27em !important;
  }
  .launch-paragraph {
    top: 50em !important;
    left: -27em !important;
  }
  .logo-image-green {
    position: relative !important;
    margin-top: 1.8em !important;
    width: 4em !important;
  }
  .mail-item-margin-style {
    margin-top: 0.1em !important;
  }
  a.button-l-2 {
    display: none;
  }
  font {
    display: none;
  }
}
@media only screen and (max-width: 350px) {
  .process-header {
    visibility: visible;
  }
  .process-pic-mobile {
    visibility: hidden;
  }
  .process-paragraph {
    margin-left: 0em;
    text-align: center !important;
  }
  .services-button {
    margin-top: -3em !important;
  }
}
